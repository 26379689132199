<template>
  <v-container class="enrollment">
    <v-row class="mt-10 d-flex justify-center">
      <v-col cols="12" sm="12" md="12" lg="7" class="pb-8">
        <v-card class="mx-auto mt-3 card" style="height: 350px">
          <v-stepper v-model="stepper" outlined>
            <v-stepper-header
              class="mt-2"
              style="box-shadow: 0px 0px !important"
            >
              <v-stepper-step
                @click="editable(stepper)"
                class="editable"
                :complete="stepper > 1"
                step="1"
                :color="stepper > 1 ? '#388E3C' : '#624fc6'"
              >
                <h3>اختر الكورس</h3>
              </v-stepper-step>

              <v-divider
                :class="stepper == 3 ? 'stepper' : ''"
                :style="stepper == 2 ? 'border-color: #624fc6 !important;' : ''"
              ></v-divider>

              <v-stepper-step
                error-icon="mdi-close-circle"
                :rules="[buy_failed]"
                :complete="stepper > 2"
                step="2"
                :color="stepper > 2 ? '#388E3C' : '#624fc6'"
              >
                <h3>دفع</h3>
              </v-stepper-step>

              <v-divider
                :style="{ borderColor: this.style_divider }"
              ></v-divider>

              <v-stepper-step
                error-icon="mdi-close-circle"
                :rules="[buy_failed]"
                :complete="stepper == 3"
                step="3"
                color="#388E3C"
              >
                <h3>انتهاء</h3></v-stepper-step
              >
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1" class="mx-auto">
                <!--//////////////// stepper 1 ///////////////// -->
                <v-card class="mb-12 mt-2 card" height="150px">
                  <v-row>
                    <v-col cols="12" sm="6" md="6" lg="6">
                      <v-autocomplete
                        ref="CoursesDropdown"
                        :items="paid_courses"
                        v-model="course"
                        color="#624fc6"
                        hide-details="auto"
                        item-text="title"
                        item-value="id"
                        return-object
                        label="اختر كورس ..."
                        :menu-props="menu_props"
                        outlined
                        rounded
                        clearable
                        lazy-rules
                        :rules="RulseAll"
                        class="font-weight-black"
                      >
                        <template v-slot:append-item>
                          <div
                            v-intersect="userInteract"
                            v-if="
                              !(
                                $store.state.enrollments.courses_state ==
                                'finished'
                              )
                            "
                            class="text-lg-center"
                          ></div>
                        </template>
                      </v-autocomplete>
                    </v-col>

                    <v-col cols="12" sm="6" md="6" lg="6">
                      <Selects
                        @update-value="payment = $event"
                        :value="payment"
                        itemValue="value"
                        itemText="type"
                        :disabled="course != null ? false : true"
                        :items="payment_methods"
                        label="اختر طريقة الدفع"
                        :rules="RulseAll"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6">
                      <v-card-actions v-if="course != null">
                        <h3>السعر :</h3>

                        <h4
                          v-if="
                            course != null &&
                            course.offer != null &&
                            moment().format('YYYY-MM-DD') <=
                              course.offer_expired
                          "
                        >
                          {{
                            (course.price - (course.price / 100) * course.offer)
                              | formatNumber
                          }}
                          الف
                        </h4>

                        <h4
                          v-else-if="
                            course != null &&
                            (course.offer == null ||
                              moment().format('YYYY-MM-DD') >=
                                course.offer_expired)
                          "
                        >
                          {{ course.price | formatNumber }} الف
                        </h4>
                        <font-awesome
                          :icon="['fas', 'sack-dollar']"
                          color="#388E3C"
                          size="xl"
                          class="mr-2"
                        />
                      </v-card-actions>
                      <v-card-actions class="mt-n2">
                        <h4
                          v-if="
                            course != null &&
                            course.offer != null &&
                            moment().format('YYYY-MM-DD') <=
                              course.offer_expired
                          "
                        >
                          قيمة الخصم :
                          <strong style="color: #624fc6"
                            >{{ course.offer }} %</strong
                          >
                        </h4>
                      </v-card-actions>
                    </v-col>
                  </v-row>
                </v-card>

                <v-btn
                  :disabled="course == null || payment == null"
                  color="#624fc6"
                  large
                  :class="$vuetify.breakpoint.xsOnly ? 'mt-16' : ''"
                  class="px-7"
                  elevation="4"
                  @click="tracking"
                >
                  <h4 style="color: white; font-size: 17px">متابعة</h4>
                  <template v-slot:loader>
                    <span class="custom-loader">
                      <v-icon color="white">mdi-cached</v-icon>
                    </span>
                  </template>
                </v-btn>
              </v-stepper-content>
              <!--///////////////// end ////////////// -->

              <!--//////////////// stepper 2 ////////////// -->
              <v-stepper-content
                step="2"
                class="mx-auto"
                v-if="payment != null && payment.value == 1"
              >
                <v-card class="mb-12 card" height="150px">
                  <v-card-title>
                    <v-radio-group v-model="radios">
                      <template v-slot:label>
                        <div class="d-flex justify-start mb-2">
                          <h3>اختر وسيلة الدفع</h3>
                        </div>
                      </template>
                      <v-radio value="ZainCashe" color="#624fc6">
                        <template v-slot:label>
                          <div>
                            <h3 style="color: black">زين كاش</h3>
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-card-title>
                </v-card>
                <v-btn
                  color="#624fc6"
                  large
                  class="px-7"
                  elevation="4"
                  @click="buy_course"
                >
                  <h4 style="color: white; font-size: 17px">دفع</h4>
                  <template v-slot:loader>
                    <span class="custom-loader">
                      <v-icon color="white">mdi-cached</v-icon>
                    </span>
                  </template>
                </v-btn>
              </v-stepper-content>

              <!-- //////////////// end /////////////////-->
              <!--//////////////// stepper 2 ////////////// -->
              <v-stepper-content
                step="2"
                class="mx-auto"
                v-else-if="payment != null && payment.value == 0"
              >
                <v-form ref="buy_course_code">
                  <v-card class="mb-12 card" height="150px">
                    <v-row>
                      <v-col cols="12" sm="12" md="8" lg="8">
                        <Input
                          @update-value="code = $event"
                          :value="code"
                          type="text"
                          label="كود الشراء"
                          :rules="RulseAll"
                        />
                      </v-col>
                    </v-row>
                  </v-card>

                  <v-btn
                    color="#624fc6"
                    large
                    class="px-7"
                    elevation="4"
                    :loading="loading"
                    @click="buy_course_code"
                  >
                    <h4 style="color: white; font-size: 17px">شراء</h4>
                    <template v-slot:loader>
                      <span class="custom-loader">
                        <v-icon color="white">mdi-cached</v-icon>
                      </span>
                    </template>
                  </v-btn>
                </v-form>
              </v-stepper-content>

              <!-- //////////////// end /////////////////-->

              <!-- //////////////// stepper 3 /////////////////-->
              <!-- v-if="status != null && status =='success'" -->
              <v-stepper-content step="3" class="mx-auto">
                <v-col cols="12" v-if="status_code == true">
                  <v-card class="mb-12 card" height="150px">
                    <h2 style="color: green">تمت عملية الشراء بنجاح</h2>
                    <v-card-title>
                      <h5 class="d-flex justify-end mt-n2">
                        فاتورة الشراء :

                        <v-btn fab small text @click="show_invoice()">
                          <Icon
                            class="icon_download"
                            icon="ph:download-fill"
                            color="#624fc6"
                            width="30"
                          />
                        </v-btn>
                      </h5>
                    </v-card-title>
                  </v-card>
                  <v-btn
                    color="#624fc6"
                    large
                    class="px-7"
                    elevation="4"
                    @click="show_courses"
                  >
                    <h4 style="color: white; font-size: 17px">عرض الكورسات</h4>
                  </v-btn>
                </v-col>
                <v-col cols="12" v-if="status != null && status == 'success'">
                  <v-card class="mb-12 card" height="150px">
                    <h2 style="color: green">تمت عملية الشراء بنجاح</h2>
                    <v-card-title>
                      <h5 class="d-flex justify-end mt-n2">
                        فاتورة الشراء :

                        <v-btn fab small text @click="show_invoice()">
                          <Icon
                            class="icon_download"
                            icon="ph:download-fill"
                            color="#624fc6"
                            width="30"
                          />
                        </v-btn>
                      </h5>
                    </v-card-title>
                  </v-card>
                  <v-btn
                    color="#624fc6"
                    large
                    class="px-7"
                    elevation="4"
                    @click="show_courses"
                  >
                    <h4 style="color: white; font-size: 17px">عرض الكورسات</h4>
                  </v-btn>
                </v-col>

                <v-col
                  cols="12"
                  v-else-if="status != null && status == 'failed'"
                >
                  <v-card class="mb-12 card" height="150px">
                    <h2 style="color: red">فشلة عملية الشراء</h2>
                  </v-card>
                </v-col>
              </v-stepper-content>
              <!--//////////////// end ///////////////-->
            </v-stepper-items>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Selects from "../components/layout/Selects.vue";
import Input from "../components/layout/Input.vue";
export default {
  components: {
    Selects,
    Input,
  },

  data() {
    return {
      radios: "ZainCashe",
      code: "",
      status: null,
      status_code: null,
      invoice: null,
      stepper: 1,
      payment_methods: [
        { type: "الالكتروني", value: true },
        { type: "كود", value: false },
      ],
      payment: null,
      course: null,
      menu_props: {
        closeOnClick: false,
        closeOnContentClick: false,
        disableKeys: true,
        openOnClick: false,
        maxHeight: 300,
        offsetY: true,
        offsetOverflow: true,
        transition: false,
      },
      RulseAll: [(v) => !!v || "هذا الحقل مطلوب"],
    };
  },
  created() {
    const dataParam = this.$route.query.data;
    console.log("dataParam", dataParam);
    if (dataParam) {
      this.stepper = 3;
      const parsedData = JSON.parse(decodeURIComponent(dataParam));
      this.status = parsedData.status;
      this.invoice = parsedData.url_invoice;
    }
  },
  mounted() {
    this.$store.dispatch("enrollments/get_paid_courses");
    this.remove_category_id();
  },
  computed: {
    paid_courses() {
      return this.$store.state.enrollments.paid_courses;
    },
    loading() {
      return this.$store.state.enrollments.buy_course_loading;
    },
    server() {
      return this.$store.state.server;
    },

    style_divider() {
      if (this.stepper == 3 && this.status == "success") {
        return "green !important";
      } else if (this.stepper == 3 && this.status == "failed") {
        return "red !important";
      } else {
        return "";
      }
    },
  },
  methods: {
    buy_course() {
      if (localStorage.getItem("category_id")) {
        let data = {};
        data["category_id"] = localStorage.getItem("category_id");
        this.$store.dispatch("enrollments/buy_course", data);
      }
    },
    buy_course_code() {
      if (this.$refs.buy_course_code.validate()) {
        if (localStorage.getItem("category_id")) {
          let data = {};
          data["category_id"] = localStorage.getItem("category_id");
          data["code"] = this.code;
          this.$store
            .dispatch("enrollments/buy_course_code", data)
            .then((res) => {
              this.invoice = res.data.result[0];
              localStorage.removeItem("category_id");
              this.$refs.buy_course_code.reset();
              this.status_code = true;
              this.stepper = 3;
            });
        }
      }
    },
    editable(stepper) {
      if (stepper == 2) {
        this.stepper = 1;
      }
    },
    buy_failed() {
      if (this.status != null && this.status == "failed") {
        return false;
      } else {
        return true;
      }
    },
    show_invoice() {
      window.open(this.server + this.invoice, "_blank");
    },

    show_courses() {
      this.stepper = 1;
      this.$router.push({ name: "PaidCourses" });
    },
    tracking() {
      localStorage.setItem("category_id", this.course.id);
      this.stepper = 2;
    },
    remove_category_id() {
      if (localStorage.getItem("category_id")) {
        localStorage.removeItem("category_id");
      }
    },

    get_paid_courses() {
      if (this.$store.state.enrollments.paid_courses == "finished") return;
      this.$store.dispatch("enrollments/get_paid_courses");
    },
    userInteract(entries, observer, isIntersecting) {
      if (isIntersecting) {
        setTimeout(() => {
          this.get_paid_courses(); // onscroll
          this.$refs.CoursesDropdown.onScroll();
        }, 500);
      }
    },
    complete() {},
  },
};
</script>
<style scoped>
.enrollment {
  margin-bottom: 60px;
}
.card-statistics {
  cursor: pointer;
  box-shadow: 0 5px 15px rgb(0 0 0 / 7%) !important;
  border-radius: 20px;
  transition: all ease 0.2s;
}
.card-statistics:hover {
  transition: all ease 0.2s;
  transform: scale(0.9);
  box-shadow: 0 5px 15px rgb(0 0 0 / 20%) !important;
}

.card {
  border-radius: 25px !important;
  box-shadow: 0px 0px 0px 0px !important;
}
.icon_download {
  cursor: pointer;
}
.editable {
  cursor: pointer;
}
.stepper {
  border-color: #388e3c !important;
}
</style>
