<template>
  <v-card class="mx-auto mt-8 card-table" width="100%">
    <v-row class="d-flex justify-center mb-9">
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-data-table
          :headers="headers"
          :items="lessons"
          :options.sync="pagination"
          :page.sync="pagination.page"
          :items-per-page="pagination.itemsPerPage"
          :loading="loading || false"
          hide-default-footer
          loading-text="جاري التحميل يرجى الأنتظار"
          height="600"
          :mobile-breakpoint="0"
        >
          <template v-slot:top>
            <v-toolbar flat class="mt-2">
              <v-toolbar-title>جدول فئات الكورسات</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="reset_table"
                    fab
                    icon
                    x-small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <Icon
                      icon="solar:smartphone-update-bold"
                      color="#624fc6"
                      width="30"
                    />
                  </v-btn>
                </template>
                <span>تحديث معلومات الجدول</span>
              </v-tooltip>
              <v-spacer></v-spacer>
              <v-text-field
                v-if="$vuetify.breakpoint.smAndUp"
                v-model="lessons_query"
                @input="query_change"
                append-icon="mdi-magnify"
                label="بحث"
                class="font-weight-black"
                :class="$vuetify.breakpoint.smOnly ? 'mb-2' : ''"
                color="#624fc6"
                outlined
                rounded
                clearable
                hide-details
              />
            </v-toolbar>
            <v-text-field
              v-if="$vuetify.breakpoint.xsOnly"
              v-model="lessons_query"
              @input="query_change"
              append-icon="mdi-magnify"
              label="بحث"
              class="font-weight-black mt-1 mb-2 px-2"
              color="#624fc6"
              outlined
              rounded
              clearable
              hide-details
            />
          </template>

          <template v-slot:item="{ item }">
            <tr>
              <td class="text-center font-weight-black">
                {{ item.title }}
              </td>

              <td class="text-center font-weight-black" v-if="item.course">
                {{ item.course.title }}
              </td>
              <td class="text-center font-weight-black" v-else>
                <h4 style="color: red">محذوف</h4>
              </td>
              <td class="text-center font-weight-black" v-if="item.user">
                {{ item.user.name }}
              </td>
              <td class="text-center font-weight-black" v-else>
                <h4 style="color: red">محذوف</h4>
              </td>

              <td class="text-center font-weight-black" v-if="item.content">
                {{ item.content | str_limit(120) }}
              </td>
              <td class="text-center font-weight-black" v-else>
                <h4 style="color: red">لايوجد</h4>
              </td>

              <td class="text-center font-weight-black">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="popViewDetails(item)"
                      fab
                      icon
                      x-small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <Icon
                        icon="material-symbols:display-settings-rounded"
                        color="#624fc6"
                        width="32"
                      />
                    </v-btn>
                  </template>
                  <span>عرض</span>
                </v-tooltip>
              </td>

              <td class="text-center font-weight-black">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="popDelete(item.id)"
                      fab
                      icon
                      x-small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <Icon
                        icon="ic:round-delete-forever"
                        color="#C62828"
                        width="32"
                      />
                    </v-btn>
                  </template>
                  <span>حذف كورس</span>
                </v-tooltip>
                <!-- <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="popEdit(item)"
                        fab
                        icon
                        x-small
                        v-bind="attrs"
                        v-on="on"
                      >
                        <Icon
                          icon="material-symbols:edit-document-rounded"
                          color="#37474F"
                          width="32"
                        />
                      </v-btn>
                    </template>
                    <span>تعديل معلومات الكورس</span>
                  </v-tooltip> -->
              </td>
            </tr>
          </template>
        </v-data-table>
        <div class="text-center py-5 d-flex justify-center">
          <v-row>
            <v-col cols="12" sm="6" md="3" lg="3">
              <v-pagination
                v-model="pagination.page"
                :length="pageCount"
                circle
                color="#624fc6"
              ></v-pagination>
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="3">
              <v-select
                v-model="pagination.itemsPerPage"
                :items="items"
                color="#624fc6"
                outlined
                rounded
                single-line
                hide-details
                reverse
                label="عدد العناصر"
                class="d-flex justify-center px-2"
              ></v-select>
            </v-col>
          </v-row>
        </div>
        <PopViewDetails
          :value="pop_view"
          v-on:popClose="popClose()"
          :image="data_view"
        />

        <PopDelete
          :value="pop_delete"
          v-on:popClose="pop_delete = !pop_delete"
          v-on:deleteLessons="deleteLessons()"
        />
        <!-- <PopEdit
                :value="pop_edit"
                :category="data_editor"
                v-on:popClose="pop_edit = !pop_edit" /> -->
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import PopDelete from "@/components/lessons/PopDelete.vue";
// import PopEdit from "@/components/courses_category/PopEdit.vue";
import PopViewDetails from "@/components/courses_category/PopViewDetails";
export default {
  components: { PopViewDetails, PopDelete },
  data() {
    return {
      pop_view: false,
      data_view: null,
      pop_delete: false,
      data_deleted: null,
      pop_edit: false,
      data_editor: null,
      items: [5, 10, 15, 20, 25],
      pagination: {},
      headers: [
        {
          text: "عنوان الدرس",
          value: "title",
          class: "secondary white--text title",
          align: "center",
          width: "190px",
        },
        {
          text: "اسم فئة الكورس",
          value: "category",
          class: "secondary white--text title",
          align: "center",
          width: "200px",
        },
        {
          text: "اسم الاستاذ",
          value: "user",
          class: "secondary white--text title",
          align: "center",
          width: "190px",
        },
        {
          text: "الوصف",
          value: "content",
          class: "secondary white--text title",
          align: "center",
          width: "190px",
        },

        {
          text: "عرض الصورة",
          value: "image",
          class: "secondary white--text title",
          align: "center",
          width: "200px",
        },

        {
          text: "العمليات",
          class: "secondary white--text title",
          align: "center",
          width: "130px",
        },
      ],
    };
  },
  computed: {
    lessons_query: {
      set(val) {
        this.$store.state.lessons.lessons_query = val;
      },
      get() {
        return this.$store.state.lessons.lessons_query;
      },
    },
    pageCount() {
      return this.$store.state.lessons.pageCount3;
    },

    lessons() {
      return this.$store.state.lessons.lessons_dashboard;
    },
    loading() {
      return this.$store.state.lessons.table_lessons_loading;
    },
    params: {
      set(val) {
        this.$store.state.lessons.params_lessons_dashboard = val;
      },
      get() {
        return this.$store.state.lessons.params_lessons_dashboard;
      },
    },
  },
  methods: {
    get_lessons_dashboard() {
      let pagination = this.pagination;
      let par = {
        ...pagination,
      };
      this.params = par;
      this.$store.dispatch("lessons/get_lessons_dashboard");
    },
    query_change() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.get_lessons_dashboard();
        this.pagination.page = 1;
      }, 1000);
    },

    popDelete(item) {
      this.data_deleted = item;
      this.pop_delete = true;
    },
    popViewDetails(item) {
      this.data_view = item.image;
      this.pop_view = true;
    },
    popClose() {
      this.pop_view = false;
      this.data_view = null;
    },
    // popEdit(item) {
    //   this.data_editor = item;
    //   this.pop_edit = true;
    // },
    deleteLessons() {
      let data = {};
      data["id"] = this.data_deleted;
      this.$store.dispatch("lessons/delete_lessons", data).then(() => {
        this.pop_delete = false;
        this.data_deleted = null;
      });
    },
    reset_table() {
      this.$store.dispatch("lessons/reset_table").then(() => {
        this.get_lessons_dashboard();
      });
    },
  },
  watch: {
    pagination: {
      handler() {
        this.get_lessons_dashboard();
      },
      deep: true,
    },
  },
};
</script>
